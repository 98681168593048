import "./desserts.scss";
import Card from "../Card/Card";
import { desserts } from "../../routes";

const Desserts = ({ href, title, string, src }) => (
    <div class="tableParent">
      <div class='table'>
        <div class="cardClass">
          <Card
            href={desserts.applePie}
            title="APPLE PIE"
            string="Autumn fauvorite."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.babka}
            title="BABKA"
            string="Easy chocolate and vanilla babka."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.carrot}
            title="CARROT CAKE"
            string="Excellent carrot cake. Comes very quickly together with minimal fuss."
            src="/images/carrot-cake.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.cheesecake}
            title="CHEESECAKE"
            string="Old family recipe. And it is very similar to German cheescake NOT American."
            src="/images/sernik.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.cookies}
            title="MY WEIRD CHOCOLATE COOKIES"
            string="A crosbreed between muffins and cookies."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.migdalowiec}
            title="GRANDMA'S ALMOND CAKE (MIGDAŁOWIEC)"
            string="This recipe is in the family for over 100 years. Absolut must have during Christmass and Easter."
            src="/images/migdalowiec.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.madeleines}
            title="MADELEINES"
            string="After many tries it came best madeleines recipe. "
            src="/images/madelens.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.muffins}
            title="MUFFINS"
            string="Amazing recipe based on Sally's Baking Addiction version. Works everytime and with everything."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.pishinger}
            title="PISHINGER"
            string="Regional delicacy. Don't try own waffer though..."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.poppyLoaf}
            title="POPPY SEED LOAF"
            string="Cake on a heathier side, and great when you have surplus egg whites."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.tiramisu}
            title="TIRAMISU"
            string="Italian classic."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={desserts.yamPie}
            title="YAM PIE"
            string="Sweet and tangy, worth a bit of effort."
            src="/images/flower.jpeg"
          />
        </div>
        
      </div>
    </div>
  );

export default Desserts;
