import React from "react";
import "./header.scss";
import Logo from "../Logo/Logo";

const Header = () => (
  <>
    <div class="header nav-links">
      <Logo />
      <h1 class="text-style webName">The Cook Book</h1>
      <p class="empty2"></p>
      <a class="starters" title="" href="/starters">
        STARTERS & SIDES
      </a>
      <a class="soups" href="/soups" title="">
        SOUPS
      </a>
      <a class="salads" href="/salads" title="">
        SALADS
      </a>
      <a class="mains" href="/mains" title="MAINS">
        MAINS
      </a>
      <a class="desserts" href="/desserts" title="">
        DESSERTS
      </a>
      <a class="tipsAndTricks" href="/tipsTricks" title="TIPS & TRICKS">
        TIPS & TRICKS
      </a>
      <a class="tipsAndTricks" href="/tipsTricks" title="TIPS & TRICKS">
        HOW TO READ RECIPES
      </a>
      <p class="empty3"></p>
    </div>
  </>
);

export default Header;
