import Recipe from "../../components/Recipe/Recipe"

const VegetableSoup = () => (
  <Recipe
  title="WINTER VEGETABLE CREAM"
  src="/images/flower.jpeg"
  string="One of not many dishes that are quick to make, easy, cheap and healthy. Remember to garnish it to take it to the next level."
    ingredients={[
      "1 liter of chicken or vegetable stock",
      "2 potatoes",
      "600 gr (approximately) of white, root veggies (celery, parsley, parsnip)",
      "1 big leek (white part)",
      "1 onion (white or yellow)",
      "2 cloves of garlic (optional)",
      "200 ml of cream (either 30%, 18% or 12%) or full-fat milk",
      "1 tbsp olive of oil",
      "Salt, white pepper, 1/2 tsp nutmeg ",

    ]}
    instructions={[
      "Gently fry leek and onion. Do not brown! When significantly soften, add garlic (grated or sliced).",
      "Add root veggies - cubed or grated. Add salt. Fry on low heat for about 15 minutes. Add stock and cubed potatoes.",
      "Bring it to the boil. After about 5 minutes when potatoes are ready start blending.",
      "It is not a final blend, so the results can be a bit chunky. Now it is the time to add dairy. Gently bring it to the boil. The leaner it is the more careful you must be.",
      "Add pepper and nutmeg. Blend it well. Taste it, and add some spices if need be.",
      "Serve with garnish. It can be a drizzle of oil, fried shallots or capers of bits of a beacon.",
    ]}
  />
);

export default VegetableSoup;
