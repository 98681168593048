import Card from "../Card/Card";
import { tips } from "../../routes";


const TipsAndTricks = ({ href, title, string, src }) => (
    <div class="tableParent">W
      <div class='table'>
        <div class="cardClass">
          <Card
            href={tips.pasta}
            title="PASTA DOUGH"
            string="So many types and so many uses. Questions that should be answered before starting working on pasta."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={tips.goat}
            title="a"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={tips.greek}
            title="a"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={tips.panzanella}
            title="a"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
       
      </div>
    </div>
  );

export default TipsAndTricks;
