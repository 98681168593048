import React from "react";
import "./tabs.scss";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

export const TabsBlob = () => {
  return (
    <Tabs isFitted variant='unstyled' className="TabsContainer">
      <TabList>
        <Tab className="footerFont">CONTACT SUPPORTERS</Tab>
        <Tab className="footerFont">CONTACT FOR BUSINESS</Tab>
        <Tab className="footerFont">SUPRISE</Tab>
      </TabList>

      <TabPanels>
        <TabPanel className="footerFont">
          <p>email: kesse8401@hotmail.com</p>
        </TabPanel>
        <TabPanel className="footerFont">
          <p>email: agnes.p.nowak@gmail.com</p>
        </TabPanel>
        <TabPanel className="footerFont">
          <a href='https://lh3.googleusercontent.com/pw/AJFCJaUcAVSduRlf7yUj0zsJp4hzdCKNKdV2zHTWt0o2ZGvDreyU-yKXy0-dVDLaKgjtT83ryvDpwIYvo7iILaE1Y7tLZjSqngcshy5dR7D7p1Cl7Q5gZBYvLsO43vo3hfSGZHIGTW1hD9gUUE0hO8QVUBsdMQ=w1476-h1968-s-no?authuser=0' alt='bear'><p>Click here</p></a>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
export default TabsBlob;
