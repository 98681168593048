import Recipe from "../../components/Recipe/Recipe";

const PotatoeGratin = () => (
  <Recipe
    title="POTATOE GRATIN"
    src="/images/polcia.png"
    string= "Recipe heavily inspired by Adam Ragussea. I like how simple it is to make. Thanks to the metod it is delicious side, rather then laborious dish itself."
    ingredients={[
      "2 tbsp of melted butter",
      "1 egg",
      "1 cup of flour (120 gr)",
      "¾ cup of buttermilk OR ¾ cup of milk (180 ml) and 2 tbsp white vinegar",
      "½ tsp bicarbonated soda",
      "1 tsp baking powder",
      "½ tsp salt",
      "3 tbsp sugar",
    ]}
    instructions={[
      "",
      "",
      "",
      "",
      "",
      "",
    ]}
  />
);

export default PotatoeGratin;
