import React from "react";
import "./card.scss";



const Card = ({href, title, blurb, src}) => (
  <li class="link-card">
    <a class='clickable' href={href}>
      <img
        class="card-img"
        alt="foto"
        src={src}
      />
      <h3 class='titleFont'>{title}</h3> 
      <p class='blurbFont' >{blurb}</p>
    </a>
  </li>
);

export default Card;
