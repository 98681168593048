import "./salads.scss";
import Card from "../Card/Card";
import { salads } from "../../routes";


const Salads = ({ href, title, string, src }) => (
    <div class="tableParent">
      <div class='table'>
        <div class="cardClass">
          <Card
            href={salads.cesar}
            title="CESAR SALAD"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={salads.goat}
            title="GOAT CHEESE SALAD"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={salads.greek}
            title="GREEK SALAD"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={salads.panzanella}
            title="PANZANELLA"
            string="gobledigook"
            src="/images/flower.jpeg"
          />
        </div>
       
      </div>
    </div>
  );

export default Salads;
