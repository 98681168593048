import React from "react";
import "./starters.scss";
import Card from "../Card/Card";
import {starters} from "./../../routes";

const Starters = ({ href, title, string, blurb, src }) => (
  <div class="ttt">
    <div class="table">
      <div class="cardClass">
        <Card
          href={starters.panacakes}
          title="American Pancakes"
          string="Best breakfast pancakes served with bacon and maple syrup"
          blurb="ooooooo"
          src="/images/polcia.png"
        />
      </div>
      {/* <div class="cardClass">
        <Card
          href={starters.mornay}
          title="Asparagus Mornay with Bacon"
          string="Elegant and fulfilling. Kasia niby robi lepsze..."
          src="/images/polcia.png"
        />
      </div> */}
      <div class="cardClass">
        <Card
          href={starters.krokiety}
          title="Croquettes with Mushrooms"
          string="With obligatory barszczyk"
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.pate}
          title="Grandma's Pâté"
          string="One of the oldest recipes in the family. An absolute must-have during holidays!"
          src="/images/pasztet.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.guacamole}
          title="Guacamole"
          string="My version of this Mexican staple."
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.patties}
          title="Patties (Paszteciki)"
          string="Often results in burned mouths and fingers but worth it!"
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.gratin}
          title="Potatoe Gratin"
          string="gobledigook"
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.salsa}
          title="Tomato Salsa"
          string="gobledigook"
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.witlof}
          title="Witlof Mornay / Endive Mornay"
          string="gobledigook"
          src="/images/polcia.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href={starters.yorkshire}
          title="Yorkshire Puddings"
          string="I can't even imagine whole roasted chicken without those!"
          src="/images/yorkshire-pudding2.JPG"
        />
      </div>
    </div>
  </div>
);

export default Starters;
