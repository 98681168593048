import Recipe from "../../components/Recipe/Recipe";

const Paszteciki = () => (
  <Recipe
    title="PATTIES (PASZTECIKI)"
    src="/images/polcia.png"
    string=""
    ingredients={[
      "2 tbsp of melted butter",
      "1 egg",
      "1 cup of flour (120 gr)",
      "¾ cup of buttermilk OR ¾ cup of milk (180 ml) and 2 tbsp white vinegar",
      "½ tsp bicarbonated soda",
      "1 tsp baking powder",
      "½ tsp salt",
      "3 tbsp sugar",
    ]}
    instructions={[
      "",
      "",
      "",
      "",
      "",
      "",
    ]}
  />
);

export default Paszteciki;
