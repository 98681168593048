import Recipe from "../../components/Recipe/Recipe"

const Chlodnik = () => (
  <Recipe
    title="CHŁODNIK"
    src="/images/chlodnik1.jpg"
    string="Polish version of gazpacho. Perfect for hot summer day. Delicious and accidentally healthy."
    ingredients={[
      "3 medium beetroots",
      "OPTIONALLY: 1 bunch of beet stalks and leaves",
      "1 bunch of parsley",
      "1 bunch of spring onion",
      "1 bunch of red radish",
      "2 medium cucumbers",
      "1/2 liter full fat Greek yogurt",
      "1/2 liter cold vegetable stock or in a pinch water",
      "½ tsp salt, 1 pinch of pepper, 1 tsp of sugar",
      "1 tbsp white vinegar OR 1/2 tsp citric acid",
      "OPTIONALLY: hard boiled eggs cubbed or halved",

    ]}
    instructions={[
      "Chop beetroots in 1cm - 1/2 inch cubes and boild it for about 15 min total. If you are using beet stalks add them 5 min after bets.",
      "Add citric asid or vinegar, salt, pepper and sugar. Keep adding untill you like it, and cool it down to fridge temperature. Make this step day earlier is even better.",
      "To the bowl add yogurt, cucumber, radish and herbs.",
      "Cold beetroot soup pour very gently and slowly in to the yougurt (not other way around or it will split!).",
      "Keep it for few hours in the fridge so all the flauvors can meld together.",
      "Serve with eggs."

    ]}
  />
);

export default Chlodnik;
