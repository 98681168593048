import Recipe from "../../components/Recipe/Recipe";

const EelSoup = () => (
  <Recipe
    title="EEL or TROUT SOUP"
    src="/images/flower.jpeg"
    string="It is, admittedly, a very weird soup. But it is fabulous. Just try it!"
    ingredients={[
      "700 gr of eel or trout fillet",
      "100 gr of sliced pancetta",
      "1 large onion",
      "1 carrot",
      "1 200 gr tin of peas (yes, it has to be from a tin)",
      "2 ripe pears",
      "1 1/2 liters vegetable or fish stock",
      "1 bunch od dill",
      "1 bunch od parsley",
      "SPICES: 1 tsp each of: majoram, sage, thyme, tarragon, black pepper and 1 bay leaf",
    ]}
    instructions={[
      "Cut slices of pancetta into small pieces and render gently at the bottom of the Dutch oven (or other solid pot). Once it released some fat add chopped onion and fry gently until translucent.",
      "Add sliced (or cubed) carrot, all dried herbs and fry for few more moments, then top it up with stock.",
      "Boil gently for about 10 minutes and add halved pears. Soup will not be served with them. Fish them along with bay leaf before serving.",
      "After 5 minutes add drained peas, and give it 2 mor minutes.",
      "Finally add chopped fresh herbes and fish fillet in bite-size chunks. Don't stirr it. Put the lid on the top.",
      "After a minute or so turn the heat off. You don't want the fish to cool down the soup too much, and you don't want to keep boiling to long because it will fall apart.",
      "Let the whole thing rest for at least 15 min. It will ensure that fish is ready and all the flauvors had time to develop. Fish out pears and bay leaf and serve.",


    ]}
  />
);

export default EelSoup;
