import Recipe from "../../components/Recipe/Recipe"

const ChickenSoup = () => (
  <Recipe
    title="Chicken Soup (Rosół)"
    src="/images/rosol1.jpg"
    string="Not so much a soup, as an essential soup base. So important in every cook's repertoire. Can be a base for so many other soups and sauces. I often utilise roasted chicken leftovers, and combine it with chicken wings, as they are cheap, small and have a lot of collagen that is great for flauvor. Also make sense to make it in your biggest pot. MIne is 12 liters and works great for this purpose. It is most versitile concotion that can be."
    ingredients={[
      "1,5 kg of chicken (wings, tights and roast leftovers) ",
      "2 leeks (green parts removed)",
      "1/4 of large celery root",
      "1 yellow onion",
      "3 parsley roots (or parsnips)",
      "2 carrots",
      "20 grains of black pepper",
      "10 grains of allspice",
      "3 bay leafs",
      "Bunch of parsley leaves (for garnish)",
      "8 liters water (of good quality aka filtered, or boiled previously)",
      "OPTIONALLY: other vegetables (it should be about 1,5kg veggies in total) such as rutabaga, celery stalk or a bit of collard greens ",
    ]}
    instructions={[
      "Rub your chicken in oil (of any kind) and roast it in the oven or air fryer along with onion chopped in half. 180C for about 40 minutes. This is crucial step. IN my opinion it gives major boost of flauvor in the end.",
      "Wash and chop your vegetables in sensible chunks. Peeling is not necessary",
      "Top it with water and spices.",
      "Add chicken parts and bring it to a gentle boil (intense boiling will cloud the soup) and leave it like that for...eee six hours.",
      "It is not strictly necessary but I like to reduce it at least in half. After straining chicken and vegetables I am usualy left with 2-3 liters of broth.",
      "Some of it I serve immediately with homemade pasta or dumplings. Some I pour into ziplock and freeze, and some goes to special ice tray to be frozen and use as a cubed stock. ",

    ]}
  />
);

export default ChickenSoup;
