import Recipe from "../../components/Recipe/Recipe"

const Cookies = () => (
  <Recipe
    title="Cesar Salad"
    src="/images/flower.jpeg"
    ingredients={[
      "2 tbsp of melted butter",
      "1 egg",
      "1 cup of flour (120 gr)",
      "¾ cup of buttermilk OR ¾ cup of milk (180 ml) and 2 tbsp white vinegar",
      "½ tsp bicarbonated soda",
      "1 tsp baking powder",
      "½ tsp salt",
      "3 tbsp sugar",
    ]}
    instructions={[
      "Combine vinegar and milk. Let it rest 10 min in a big glass container.",
      "Melt butter until liquified. Cool it slightly",
      "Mix flour with all dry ingredients.",
      "Mix egg and butter with milk. Add all dry ingredients to wet. Mix it by hand, but not too long",
    ]}
  />
);

export default Cookies;



