import Recipe from "../../components/Recipe/Recipe"

const LentilSoup = () => (
  <Recipe
  title="LENTIL SOUP"
  src="/images/flower.jpeg"
  string="My go-to diet soup. It is fulfilling and flavorful while very low on calories, astounding 108 calories per portion. The quantities below will yield 4 portions."
    ingredients={[
      "½ cup or 100 gr of dried green lentils",
      "1 red bell pepper",
      "1 carrot",
      "1 stalk of celery greens or cubed celery root",
      "1 leek",
      "1 potatoe",
      "1 onion",
      "3 cloves of garlic",
      "1,2 liters of vegetable stock. Can be from the cubed bullion. Just don't tell enybody!",
      "SPICES: 1 tsp each: tarragon, herbs de Provance, oregano, ½ tsp of sweet (or spicy) smoked paprika, salt and pepper. ",

    ]}
    instructions={[
      "Heat oil of oil",
      "",
      "",
      "",
      "",
      "",
    ]}
  />
);

export default LentilSoup;
