import React from "react";
import "./footer.scss";
import Tabs from "../Tabs/Tabs.js";


const Footer = () => (
  <div class="mainFooter">
    < Tabs />
      </div>
  
);

export default Footer;
