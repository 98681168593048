import Recipe from "../../components/Recipe/Recipe"

const Carbonara = () => (
  <Recipe
    title="CARBONARA's AND CACIO E PEPE's BABY"
    src="/images/carbonara.jpeg"
    string="Best from both dishes. Ingredients below are per one dinner portion, you can easiily multiply, but I would hesitate to make more then 2 portions at once."
    ingredients={[
      "35 gr of finely grated Pecorino Romano",
      "20 gr of finely grated Parmigiano Reggiano",
      "110 gr of dry pasta, preferably spaghetti or linguine",
      "50 gr of 1 cm2 cubed guanciale, preferably from jowl not cheeks",
      "1/2 tsp of freshly ground pepper",
      "1 egg yolk",
    ]}
    instructions={[
      "Put pasta in to dry, unsalted water and set timer for when is done.",
      "Melt a bit of fat (ideally rendered from bacon on previous ocasion) and once sauce pan is hot ad guanciale. Fry gently stirring often. At the end you want crunchy on the outside and soft inside.",
      "Great cheeses and add egg yolk. At this point pasta should be half way through, so take some pasta water in to a mug. It will chill quicker.",
      "Once guanciale is done place it in a plate. Remove most fat (I have a jar of the stuff in the fridge. It often comes handy) leaving about 1-2 tsp. Add few twist of black pepper to the pan to fry for few seconds and tun off the heat.",
      "When pasta is done, add it to sauce pan. Dont worry about some water coming along. Mix some pasta water from the mug from yolk with chees untill you have thick liquid. Pour it slowly to the pasta mixing constantly.",
      "If i is too dry add some additional pasta water. If it's too loose add some grated cheese. It is better however to start cautiously with the water.",
      "Once you happy with the consistency add guanciale and serve.",

    ]}
  />
);

export default Carbonara;



