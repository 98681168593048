import "./mains.scss";
import Card from "../Card/Card";
import { mains } from "../../routes";

const Mains = ({ href, title, string, src }) => (
    <div class="tableParent">
      <div class='table'>
        <div class="cardClass">
          <Card
            href={mains.zrazy}
            title="BEEF ROULADES (ZRAZY)"
            string="Old traditional Polish recipe for fancy dinners."
            src="/images/zrazy.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.bolognese}
            title="RAGÙ BOLOGNESE"
            string="Every cook has their own recipe! This one is great with both spaghetti pasta and lasagne."
            src="/images/bolognese.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.burrito}
            title="BURRITO"
            string="One of my first recipes, lovingly developed over the years."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.golabki}
            title="CABBAGE ROLLS (GOŁĄBKI)"
            string="Traditional, frustrating and delicious!"
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.carbonara}
            title="CARBONARA's AND CACIO E PEPE's BABY"
            string="I like both of those pastas, and rarely could chose between. So I have been making the hybryd and it is delicious."
            src="/images/carbonara.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.portChicken}
            title="CHICKEN WITH MUSCHROOMS AND PORT WINE"
            string="Recipe that came incidentally, but came out so well that it stayed in the menu."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.wineChicken}
            title="CHICKEN IN RED WINE WITH WHITE ASPARAGUS SAUCE"
            string="Delicious, elegant and fairly simple."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.chilli}
            title="BEEF CHILLI"
            string="Old classic, easy to make, with large margin error. Delicious and hearthy."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.coqAuVin}
            title="COQ AU VIN"
            string="Recipe based on great Adam Ragussea version. Elegant, delicious and simple to make."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.cottagePie}
            title="COTTAGE PIE"
            string="Old school cool."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.duck}
            title="DUCK CONFIT"
            string="Almost imposible to mess it up. And fabulous."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.enchiladas}
            title="ENCHILADAS"
            string="Simple, tasty and fullfiling."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.falafel}
            title="FALAFEL"
            string="My version of this mediterranean dish. Taste great and it is healthy too. No dip frying, just air fryer or oven."
            src="/images/falafel.png"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.goatPasta}
            title="GOAT CHEESE AND TOMATOES PASTA"
            string="I tried tik tok sensational pasta and was dissapointed.Few tweaks and this came about."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.köttbullar}
            title="KÖTTBULLAR"
            string="Based on recipe by famous Swedish chef Niklas Ekstedt. Family fauvorite."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.lamb}
            title="LAMB SHANKS"
            string="Another great recipe borrowed from Adam Ragussea."
            src="/images/lamb.png"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.moussaka}
            title="MOUSSAKA"
            string="It was love at first bite. Since that first bite I learned how to make it at home, and make it tase heavenly."
            src="/images/moussaka.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.muschroomPasta}
            title="TAGLIATELLE WITH WILD MUSCHROOMS"
            string="Mound of messy deliciousnes. Very simple, very delectable."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.quiche}
            title="QUICHE"
            string="Healthier and easier - crustles quiche."
            src="/images/quiche.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.salmon}
            title="SALMON IN CURRY SAUCE"
            string="First time I heard I was like: 'whaaaaat?', but it works pretty great together. "
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.shepherdPie}
            title="SHEPHARD's PIE"
            string="Delicious and hearthy."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.smashBurgers}
            title="SMASH BURGER"
            string="Easy, delicious and all year round - if you hve a GREAT ground beef."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.spinachPasta}
            title="SPINACH PASTA"
            string="One of my oldest and greatest recipes. My child never learned that spinach can taste bad."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.tortellini}
            title="TORTELLINI WITH WILD MUSCHROOMS AND BURRATA"
            string="For most of my life my most fauvorite dish. With creamy parmesany sauce."
            src="/images/flower.jpeg"
          />
        </div>
        <div class="cardClass">
          <Card
            href={mains.uszka}
            title="POLISH CHRISTMAS TORTELLINI (USZKA)"
            string="First time I heard I was like: 'whaaaaat?', but it works pretty great together. "
            src="/images/flower.jpeg"
          />
        </div>

      </div>
    </div>
  );

export default Mains;
