import Recipe from "../../components/Recipe/Recipe";

const Krokiety = () => (
  <Recipe
    title="CROQUETTES WITH MUSHROOMS"
    src="/images/polcia.png"
    string="Delicious, fulfilling and make you happy and sleepy."
    ingredients={[
      "500 gr APF",
      "500 gr APF",
      "500 gr APF",
      "500 gr APF",

      "1 kg of fresh champignons",
      "2 - 3 medium onions",
      "1 tbsp butter - can be cold",
      "2 tsp of salt (minimum)",
      "1 tsp of pepper (minimum)",
      "½ tsp salt",
      "3 tbsp sugar",
    ]}
    instructions={[
      "",
      "",
      "",
      "",
      "",
      "",
    ]}
  />
);

export default Krokiety;
