
import Recipe from "../../components/Recipe/Recipe"

const FishChowder = () => (
  <Recipe
  title="FISH CHOWDER"
  src="/images/flower.jpeg"
  string="This is recipe of my uncle. Well traveled sailor with Norwegian roots, came up with amazing mix of flauvors and textures. It can be completly mild or very spicy - it is up to you."
    ingredients={[
      "150 gr cubed salmon fillet",
      "150 gr cubed haddock or cod",
      "150 gr surimi (best you can find, preferably with some crab in it)",
      "150 gr cooked prawns or shrimp",
      "400 ml coconut milk",
      "100 ml milk or cream",
      "1 tsp of tom yum paste (ready or home made)",
      "3 cloves of garlic",
      "30-40 gr of fresh ginger",
      "1 stalk of celery",
      "1 small parsley or parsnip",
      "2 onions",
      "2 potatoes",
      "800 ml of fish stock",
      "SPICES: 2 bay leafs, chilli powder (or you can use fresh), salt (if any), black pepper"
    ]}
    instructions={[
      "Chop all vegetables in cubes. It is a chowder so stick to the bigger chunks.",
      "In a big pot heat the oil on medium heat and add chopped carrot,celery, onion and parsnip. Fry for few minutes until they will soften. Then add minced/chopped garlic, chilli (if fresh) and ginger in big pieces (so it would be easy to find it and remove it later). Again fry for few minutes.",
      "Add spoon of tom yum paste and fry gently for 30 sec. Top it up with fish stock, coconut milk, and cream. Add spices and stir in cubed potatoes. Simmer gently until potatoes are almost done (around 15 min).",
      "On the top of the soup put down gently fish and after 1 min turn the heat off. Do not stir. Now add crabsticks and prawns. Cover back with lid, and let it rest for at least 20min.",

    ]}
  />
);

export default FishChowder;
