import Recipe from "../../components/Recipe/Recipe";

const YorkshirePudding = () => (
  <Recipe
    title="YORKSHIRE PUDDING"
    src="/images/yorkshire-pudding2.jpg"
    string="Old British classic. It is an absolute must-have with Sunday Roast Dinner! Quantities below yelds 12 regularly sized yorkshire puddings."
    ingredients={[
      "4 eggs",
      "140 gr APF",
      "200 ml milk 4%",
      "1 strong pinch of salt",
      "OPTIONALLY: ½ tsp each bicarbonated soda and baking powder",
      "sunflower oil",

    ]}
    instructions={[
      "Preheat your oven to 220C (fan)",
      "Pour oil to the holes in muffin tin. Only to cover the bottom. Using your fingers spread oil on to the edges too.",
      "Put the muffin tin into the oven.",
      "Mix eggs, flour and salt (olso baking powder and soda if using). You can use either hand blender or standing mixer or food processor. I had great results either way.",
      "Slowly pour milk while mixing.",
      "Take muffin tin out of the oven, and gently pour mixture in to 12 holes. Try to spread batter evenly. Put it back in the oven (top rack) and immediately reduce heat to 210C",
      "Bake for 20 minutes, but keep eye on it. Depending on the oven it can take 15 min and it can take 25."
    ]}
  />
);

export default YorkshirePudding;
//mama how do you feel about all that cleaning?