import Recipe from "../../components/Recipe/Recipe"

const CrayfishSoup = () => (
  <Recipe
    title="CRAYFISH SOUP"
    src="/images/flower.jpeg"
    string="Increadibly delicious, fancy and labour intense. And of course seasonal. Usually, it is a whole family undertaking.."
    ingredients={[
      "1 kg of whole crayfish (fresh or frozen and defrosted)",
      "3 bunches of dill",
      "125 gr butter",
      "1 cup of sour cream",
      "3 tbsp of APF",
      "1 liter of light vegetable stock",
      "SPICES: 1 tsp nutmeg, 1/2 tsp white pepper, salt",
    ]}
    instructions={[
      "Boil raw crayfishes in a big pot of water with two bunches of dill for about 15 minutes.",
      "Cool down. Peel and separate the tails. Put it aside. All unedible parts flush with fresh water and spread on the oven tray. Put it in the hot oven to thoughryly dry them out.",
      "Once dried and cooled move it to the big ziplock bag. Use rolling pin or heavy lided sauce pan to smash it as finely as possible.",
      "In the small auce pan melt the butter and add the 'crayfish powder'. Boil very gently for 30 min. If need add few tbsp of stock.",
      "Once it's ready strain the butter throu gauze or cheese cloth.",
      "Use butter to make light roux. Finish it with stock.",
      "Add spices, finely chopped bunch of dill and a dollop of sour crem.",
      "Once you think it's perfect ad crayfish tails (chopped or whole) and serve.",




    ]}
  />
);

export default CrayfishSoup;
