import Recipe from "../../components/Recipe/Recipe"

const CarrotCake = () => (
  <Recipe
    title="CARROT CAKE"
    src="/images/carrot-cake.jpeg"
    ingredients={[
      "140 gr carrots peeled and shredder",
      "50 gr walnuts crushed",
      "3 large eggs",
      "175 gr brown sugar OR white sugar and 1 tbsp of molasses",
      "180 ml sunflower oil",
      "1 tsp baking soda",
      "2 tsp baking powder",
      "1/2 tsp salt",
      "1 tsp cinnamon",
      "200 gr cream cheese",
      "40 gr butter in room temperature",
      "80 gr powdered sugar",
      "OPTIONALY: some decorations such as wallnuts, marzipan, raisins",

    ]}
    instructions={[
      "Preheat oven to 175C.",
      "Line 20cm round baking tin.",
      "Mix all the ingredients in a food processor EXEPT carrots and raisins.",
      "When well mixed, add raisins and carrots. Fold or mix gently.",
      "Pour batter in to the tin and bake 50-60 min until skewer comes clean.",

    ]}
  />
);

export default CarrotCake;



