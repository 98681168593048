import Recipe from "../../components/Recipe/Recipe"

const VegetableSoup = () => (
  <Recipe
  title="VEGETABLE SOUP"
  src="/images/flower.jpeg"
  string="Worldwide known soup, but not only every country makes it different, but every cook has their own version. It's the best in the spring when you can get fresh green beans and baby cauliflowers, but it can be made all year round."
    ingredients={[
      "1½ liter of chicken stock (it's nice to use a real deal here)",
      "1 baby cauliflower (or ½ small cauliflower) florets",
      "150 gr potato cubed",
      "150 gr green (or yellow) beans",
      "80 gr of cubed parsley root",
      "80 gr of cubed carrot",
      "80 gr of cubed celery root",
      "1 bunch of parsley",
      "Salt and pepper",

    ]}
    instructions={[
      "The most important thing in this soup is to remember the timing of veggies! Beens is cooking for at least 15 min.",
      "Sadly it is impossible to provide exact timing depending on the fact that everybody's cubes are different size and vegetable can be fresh or frozen. But I will try :)",
      "Bring chicken stock to the boil and add green beans. After about 8 minutes when it is halfway, add carrot, parsley and celery. When carrot is firm but not raw (about 5 min) add potato and cauliflower.",
      "Whole soup should take no longer than 20 min, or your vegetables will turn mushy. Remember then even when you will turn the heat off it still will be cooking in residual heat.",
      "The best moment to turn the heat of when some of the veggies are still al dente, but all are cooked.",
      "Serve with chopped parsley on the top.",
    ]}
  />
);

export default VegetableSoup;
