import Recipe from "../../components/Recipe/Recipe"

const Cheesecake = () => (
  <Recipe
    title="GRANDMA's CHEESECAKE"
    src="/images/flower.jpeg"
    string="This recipe is one of our family oldest recipes. The cheescake is moist and creamy.Ingredients below are for large round baking tin. You can youse small (20 cm or 8 inch) tin with half of the ingredients."
    ingredients={[
      "1 kg of white cheese such as cottage chees or quark. Preferably minced.",
      "8 eggs",
      "2 tsp vanilla extract",
      "250 gr of butter (OR some people have better results with margarine",
      "250 gr sugar",
      "2 tbsp potatoe starch",
      "OPTIONALY: raisins and candied orange rind",
     
    ]}
    instructions={[
      "Cream fat and suger until well combined and smooth.",
      "Keep adding bits of cheese and yolks. Eventually add vanilla, raisins and potato starch.",
      "Separately beat egg whites until peaks are stiff.",
      "Very gently fold cheese mix and egg whites.",
      "Bake in parchment (basque style) or on shortcrust bottom (seperate recipe) and greased edges of baking tin.",
      "Bake for about 1 hour in 170C (fan). It's ready when edges are set and golden brown and midlle looks a bit jiggly.",

    ]}
  />
);

export default Cheesecake;



