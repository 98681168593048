import Recipe from "../../components/Recipe/Recipe"

const FrenchOnion = () => (
  <Recipe
  title="FRENCH ONION SOUP"
  src="/images/flower.jpeg"
  string="My take on the classic. Not too much innovation. Word of advice, If you live in a household with more then one adult hide your sherry well. And I don't do this whole business of one giant piece of bread on the top. It is unwieldy, and more then once I splatter myself with soup trying to dissect it with my spoon. So I guess my innovation would be cheese croutons on the top. This way you can eat it comfortably, and have all the flavors without mess and brick of cheese in your stomach."    ingredients={[
      "1 - 1½ kg of yellow onion (depence on how thick you like your soup)",
      "1½ liter of beef stock (preferably home made, this is one of those recipes when it really matters)",
      "Big splash of dry sherry (or some other dry, fortified wine made of white grapes)",
      "1-2 tbsp APF",
      "60 gr butter",
      "Cubed ciabatta, preferably one day old",
      "Finely shredded Gruyere cheese",
      "1 tsp grated Parmigiano Reggiano",
      "1 tbsp sugar",
      "1 tbsp Worcestershire sauce",
      "SPICES:1½ tsp thyme, ½ black pepper, 2 bay leafs",
    ]}
    instructions={[
      "Peel and half the onions from root to tip. Lie each half flat on the chopping block. Slice them in the same direction (root to tip). You my want to employ some help. It will go very quickly and you can both moan and complain about crying.",
      "Most important step is to get onions right. You want the soft, carmelised and nutty. Not burn-bitter or tough. You can go two ways here. Traditionally you melt the butter on low heat, add chopped onions, sprinkle with sugar and salt and start mixing. It will tak awhile. AWHILE!",
      "Another method is more risqe but saves a lot of labour. It is Brian Lagerstrom's method (great cook!). Into a large heavy bottomed pot or dutch oven, add in olive oil, chopped onions, butter, and salt. Mix it well, cover and put in to 200C oven.",
      "After one hour take it out, mix it, scrape it and return it to the oven. After 1½ you should be close to where you want to be. Add dried thyme (or fresh sprigs) and return it to the oven for final 15 minutes. ",
      "When you have your onions ready sprinkle it with flour and pepper. Fry gently untill flour is not raw anymore.",
      "Deglaze with sherry. Top it with beef broth, and simmer for about 10 minutes.",
      "Preheat the oven to 200C. Spray your cubed ciabatta with oil of oil (I use garlic oil). Sprinkle with salt and pepper. Spread it on the oven tray, on the parchment. Top it up with parmesan and gruyer.",
      "Put it for few minutes until cheese is melted, and started browning. Take it from the oven and sracpe the whole thing. That ensures that all of the cheese will coat the croutons.",
      "Serve your hard earned soup with croutons on the top.",


    ]}
  />
);

export default FrenchOnion;
