import Recipe from "../../components/Recipe/Recipe"

const Kotbullar = () => (
  <Recipe
    title="KÖTTBULLAR"
    src="/images/flower.jpeg"
    string='Most often served with potato puree, mushroom or onion gravy and ligonbery or cranberry jam.Ingredients below will produce around 24 meatballs.'
    ingredients={[
      "400 gr meat (50:50 pork and beef or you can also add some veal to the mix)",
      "1 egg",
      "60 ml cream (whipping or 18% would be best) or regular milk",
      "2 tbsp bread crumbs (I use mix of regular and panko)",
      "1 onion on the smaller side (for meatballs)",
      "1 onion on the bigger side (for sauce)",
      "200 gr (approximately) champinones OR few shredded dried mushrooms soaked an hour before",
      "200 ml beef boulion/stock",
      "50 ml (more or less) cream (whipping or 18% would be best)",
      "1 tbsp butter",
      "1 tbsp flour",
      "1/2 tsp dijon mustard",
      "1/2 tsp each salt, pepper, nutmeg, allspice",
      "Cranberry or ligonberry jam. About 1 tbsp per portion.",
      "OPTIONALY: one pinch of clove",


    ]}
    instructions={[
      "Breadcrumbs mix with 60 ml cream or milk and spices for at least 10 min.",
      "Chop onion very finely and fry on the pan until golden. I use 12' pan that I would use later for meatballs.",
      "Mix egg, wet breadcrumbs, onion and meat in the mixer or food procesor. Shortly but agressively.",
      "Try form a meatball. If it's too dry add splash of milk. If its too loose add a bit of bread crumbs.",
      "Form kotrbullar and put it on the tray. I use gloves (because yyyack!). Try form them around 4 cm or 1,5 inch.",
      "Fry gently on a non stick pan or cast iron skillet. Shake often or turn with kitchen tongs, so they would brown on all sides.",
      "Once they more or less ready, transfer them to a plate or bowl. On still hot pan melt 1 tbsp of butter and fry onions and mushrooms (if fresh). WHen fried, add 1tbsp of flour.",
      "Fry gently untill for additional minute. Deglaze with beef boulion. Add mustard, pepper and salt if needed. Add splash of cream when almost ready and return kottbullar to the sauce. It will allow fluvors to meld, and finish cooking process for meatballs without drying.",
      "Serve with puree and jam.",

    ]}
  />
);

export default Kotbullar;



