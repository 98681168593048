import React from "react";
import "./hPcontent.scss";
import Card from "../Card/Card";
import "../Card/card.scss";

const HPcontent = ({ href, title, string, src }) => (
  <div class="tableParent">
    <div class='table'>
      <div class="cardClass">
        <Card
          href="/Starters"
          title="Starters & Sides"
          string="Great sides, starters, breakfast and everything else that's not a meal itself!"
          src="/images/gratin.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href="/Soups"
          title="Soups"
          string="Hearthy and delicious often souffice as a whole meal."
          src="/images/chlodnik.jpg"
        />
      </div>
      <div class="cardClass">
        <Card
          href="/"
          title="Salads"
          string="Only a few, but delicious ones."
          src="/images/cesarSalad.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href="/"
          title="Mains"
          string="Honed and perfected, often through many generations of my family."
          src="/images/bolognese.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href="/"
          title="Desserts"
          string="Variable, tasty, multicultural."
          src="/images/tort-pistacjowy.png"
        />
      </div>
      <div class="cardClass">
        <Card
          href="/"
          title="Tips & Tricks"
          string="Hard earned knowledge about techniqes and cooking processes."
          src="/images/pastaWrack.jpg"
        />
      </div>
    </div>
  </div>
);

export default HPcontent;
