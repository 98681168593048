export const starters = {
  root: "starters",
  panacakes: "starters/american-pancakes",
  mornay: "starters/asparagus-mornay",
  krokiety: "starters/krokiety",
  pate: "starters/grandmas-pate",
  guacamole: "starters/guacamole",
  patties: "starters/patties",
  gratin: "starters/potatoe-gratin",
  salsa: "starters/tomato-salsa",
  witlof: "starters/witlof-mornay",
  yorkshire: "starters/yorkshire-pudding",
};

export const soups = {
  root: "soups",
  borsht: "soups/borsht",
  broccoli: "soups/cheddar-and-broccoli",
  rosol: "soups/chicken-soup",
  chlodnik: "soups/chlodnik",
  cornChowder: "soups/corn-chowder",
  elote: "soups/corn-cream-soup",
  crayfish: "crayfish/crayfish-soup",
  eel: "soups/eel-soup",
  french: "soups/french-onion",
  fishChowder: "soups/fish-chowder",
  krupnik: "soups/krupnik",
  lentil: "soups/lentil-soup",
  potage: "soups/potato-and-leek",
  tomato: "soups/tomato-and-roast-pepper",
  vegetable: "soups/vegetable",
  winterveggies: "soups/winterveggies",
};

export const salads = {
  root: "salads",
  cesar: "salads/cesar-salad",
  goat: "salads/goat-cheese-chicken-salad",
  greek: "salads/greek-salad",
  panzanella: "salads/panzanella",
}

export const mains = {
  root: "mains",
  zrazy: "mains/beef-roulades",
  bolognese: "mains/bolognese-ragù",
  burrito: "mains/burrito",
  golabki: "mains/cabbage-rolls",
  carbonara: "mains/pasta-carbonara-e-pepe",
  wineChicken: "mains/chicken-asparagus-wine",
  portChicken: "mains/chicken-port",
  chilli: "mains/beef-chilli",
  uszka: "mains/christmas-tortellini",
  coqAuVin: "mains/coqAuVin",
  cottagePie: "mains/cottagePie",
  duck: "mains/duck-confit",
  enchiladas: "mains/enchiladas",
  goatPasta: "mains/goat-cheese-tomato-pasta",
  falafel: "mains/falafel",
  köttbullar: "mains/köttbullar",
  lamb: "mains/lamb-shanks",
  moussaka: "mains/moussaka",
  quiche: "mains/quiche",
  salmon: "mains/salmon",
  shepherdPie: "mains/shephard's-pie",
  shreddedBeef: "mains/shredded-beef-mexican-style",
  smashBurgers: "mains/smash-burgers",
  spinachPasta: "mains/spinach-chicken-pasta",
  muschroomPasta: "mains/wild-muschroom-pasta",
  tortellini: "mains/tortellini-muschrooms-burrata",
}

export const desserts = {
  root: "desserts",
  applePie: "desserts/apple-pie",
  babka: "desserts/babka",
  carrot: "desserts/carrot-cake",
  cheesecake: "desserts/best-cheesecake",
  cookies: "desserts/my-weird-chocolate-cookies",
  madeleines: "desserts/madeleines",
  muffins: "desserts/muffins",
  migdalowiec: "desserts/grandmas-almond-cake",
  pishinger: "desserts/pishinger",
  poppyLoaf: "desserts/poppy-seed-loaf",
  tiramisu: "desserts/tiramisu",
  yamPie: "desserts/yam-pie",
}

export const tips = {
  root: "tips-and-tricks",
  pasta: "tips-and-tricks/pasta-dough",
}
