import Recipe from "../../components/Recipe/Recipe";

const Guacamole = () => (
  <Recipe
    title="GUACAMOLE"
    src="/images/polcia.png"
    string="I have seen many 'Authentic' recipes, but find a combination that works best for me. Perhaps you will like it too."
    ingredients={[
      "1 ripe avocado",
      "½ small lime - juiced",
      "1 small bunch of coriander",
      "3 small cocktail tomatoes",
      "1/4 of an onion (or 25 gr)",
      "SPICES: 1/4 tsp of cumin and coriander. Pinch of salt and pepper",

    ]}
    instructions={[
      "Well... mix it all together.",
      "Some people are mushing avocado with a fork, and chopping the rest. Some people use mortar and pestle. I use a mini food processor (that came with my hand blender) because it is fuss-free, I like it smooth and I can serve in it.",
      "The advice I can give is: better less onion than too much. If it doesn't taste great add half mini tomato.",
      "",
      "",
      "",
    ]}
  />
);

export default Guacamole;
