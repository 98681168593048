import Recipe from "../../components/Recipe/Recipe"

const CornSoup = () => (
  <Recipe
    title="ELOTE or CREAM OF CORN"
    src="/images/flower.jpeg"
    string="Let me just admit honestly this is my version of the soup, probably far cry from the original. I ate it once in a restaurant, fell in love, and recreated at home. It should be rather spicy, that way it's the best! It is probably the easies and fastest soup ever made."
    ingredients={[
      "2 400 gr cans of sweet corn ",
      "1 onion",
      "4 tbsp sour cream",
      "1 liter of chicken stock (or water 1 bullion)",
      "SPICES: Smoked paprika, pepper, salt, 1 tbsp turmeric",
  
    ]}
    instructions={[
      "In a pot fry dried corn in a bit of lard. Add chopped onion. Once everything get a bit of the color add stock.",
      "Cook gently for 10-15 min. Mix everything well. And I mean very very well. You don't want to know what happens if you dont.",
      "Once perfectly smooth add turmeric. I know it's a lot, but trust me, it is needed and not for colour but for flauvor. Then add sour cream.",
      "Mix it well. If it does not taste awesome, add more turmeric. If it still doesn not taste amazing add sour cream. When you are happy with the flauvor, it's ready.",
      "You can top it more sour cream, if you like heterogeneity and also with fried tortilla or nachos. It is not necessary however.",

    ]}
  />
);

export default CornSoup;
