import Recipe from "../../components/Recipe/Recipe"

const Potage = () => (
  <Recipe
  title="LEEK AND POTATO SOUP"
  src="/images/flower.jpeg"
  string="Some call it Vichyssoise (but it's only true if it's cold, and then I don't want to eat it!), some Potage Parmentier (especialy if they French. I call it Leek and Potato soup. It is super easy, cheap, requires very few ingredients, and it simples way to apease hungry crowd in no time."
    ingredients={[
      "1 tbsp of melted butter",
      "3 leeks or 350 gr",
      "3 potatoes or 300 gr",
      "500 ml or 2 cups of chicken stock",
      "½ onion (non traditional but I like it)",
      "80 ml whipping cream (30% fat)",
      "½ tsp salt",
      "½ tsp white pepper",

    ]}
    instructions={[
      "Melt butter in a large pot over medium heat. Cook and stir leeks and onion in the melted butter until completly softened, about 15 minutes.",
      "Add chicken stock and chopped potatoes. Bring it to the boil and reduce heat untill potatoes are ready.",
      "Turn the heat off. Add salt and pepper. Mix it very well with hand blender",
      "Serve it with garlic bread on the side. For healthier option 1 tsp oil of oil on the top goes a long way.",
    
    ]}
  />
);

export default Potage;
