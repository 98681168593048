import Recipe from "../../components/Recipe/Recipe";

const GrandmasPate = () => (
  <Recipe
    title="GRANDMA'S PÂTÉ"
    src="/images/pasztet.jpeg"
    string="Possibly the oldest recipe in the family, at least 130 years old! Quantities below are enough for two medium pâtés"
    ingredients={[
      "1 kg pork neck (or medium fatty pork meat)",
      "1 kg of wild game (duck, boar, deer)",
      "350 - 400 gr non-smoked pork belly or bacon",
      "½ kg beef (I use stew variety)",
      "4 onions",
      "250 gr chicken liver",
      "4 eggs",
      "2 tsp (per 1 kg of the minced mix without eggs and bread)",
      "1 tsp black pepper (per 1 kg of the minced mix without eggs and bread)",

    ]}
    instructions={[
      "Pour one mug of water into a big pot and poach/boil meat gently with onions, until meat is cooked.",
      "Put livers on the top, cover with the lid and turn off the heat.",
      "When all of it cools down remove water and mince it twice on the meat grinding machine (medium holes). ",
      "Add salt and pepper and soaked breadcrumbs. Mix it well.",
      "And here is the tip: If I need only one medium pâté, at this point I split the mixture 2 parts and put one in the freezer in a bag.",
      "You can defrost later, add eggs and bake. You can't freeze it with raw eggs in it!",
      "Now that you make an informed decision about your pâté-needs you can add eggs, and mix it.",
      "My grandma was greasing and breadcrumbing the loaf pan. That gives you a nice crust but it is more difficult to remove pate from the pan. The way to do it is cool down pâté completely and then gently heat the bottom on the stove. Then also gently turn it over on the plate.",
      "If you don't like unnecessary risks in your life, line the loaf pan with parchment paper.",
      "Bake for 50 minutes in 170C (335F).",
      "I like to eat it on its own but many members of my family garnish it with horseradish sauce. There were also very successful attempts at cranberry jam.",
    ]}
  />
);

export default GrandmasPate;
