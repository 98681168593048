import Recipe from "../../components/Recipe/Recipe"

const Krupnik = () => (
  <Recipe
  title="EEL or TROUT SOUP"
  src="/images/flower.jpeg"
  string=""
    ingredients={[
      "1 liter of chicken stock (check my chicken soup recipe)",
      "1 carrot",
      "1 parsley",
      "1 potato",
      "5 gr dried porcini mushrooms ",
      "100 - 150 gr barley groats",
      "1 bunch of fresh parsley",
      "Salt and pepper - how much? Up to you",
    ]}
    instructions={[
      "Break mushrooms into small pieces, place in a bowl and top it with boiling water. Leave it for at  least 30 min.",
      "Rince barley groats on the collander until water runs clear. Remember that their time of cooking is 15 min.",
      "Cube vegetables and fry them for few minutes on gentle heat. Top up with chicken stock and porcini, along with soaking water.",
      "Once it is boiling add barley groats. After 5 - 10 min (depending if you have starchy or waxy potatoes respectively) add cubed potatoes.",
      "Add salt and pepper to your liking. Serve with heavy sprinkling of chopped parsley.",
    
    ]}
  />
);

export default Krupnik;
