import Recipe from "../../components/Recipe/Recipe"

const TomatoSoup = () => (
  <Recipe
  title="ROASTED TOMATO (AND PEPPER) SOUP"
  src="/images/flower.jpeg"
  string="Extremely easy, always delicious, and truth be told, tasting different every time. Amounts and quantities are just a guidlines in this case. You can also skip pepper and make it pure tomato soup. If you want to make it more Italian add basil, if you want to make it more Mexican add adobo and cumin. As a tip I can advise against using very small tomatos. While being flauvorfull they will also has a lot of skin."
    ingredients={[
      "5 roma tomatos (or 3 Rosamundas, if you can put hands on them, or 1 400gr tin of san marzano)",
      "1 onion",
      "3 cloves of garlic skin on",
      "1 red bell pepper, but can be orange or yellow",
      "SPICES: salt, pepper, and anything else really...",
      
    ]}
    instructions={[
      "Preheat oven to 190C (375F).",
      "Halved tomatoes (or quotered if they are really big), un-peeled garlic, halved and peeled onion rub in oil of oil, quotered de-seeded pepper, and place on baking tray.",
      "Place in the oven and bake for aproximately 30-35 min. Keep and eye on it for the first time so it would not burn - every oven is different!",
      "When it cools down a bit, remove tomato skins that has peeld off, and remove garlic peels too.",
      "Blen everything smootly and add herbs and/or spices of your choice.",
      "I like to serve it with garlic bread on the side, or fried tortilla on the top. Or a splash of cream. That soup really is the epitome of versatility!",
      
    ]}
  />
);

export default TomatoSoup;
