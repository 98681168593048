import "./soups.scss";
import Card from "../Card/Card";
import { soups } from "../../routes";

const Soups = () => (
  <div class="tableParent">
    <div class="table">
      <div class="cardClass">
        <Card
          href={soups.borsht}
          title="Borsht (Barszcz)"
          string="Staple during Polish Christmas, but it goes famously with many other dishes."
          src="/images/barszcz&uszka2.jpg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.broccoli}
          title="Cheddar & Broccoli Soup"
          string="Copycat of copycat of copycat - so much that it is my recipe now."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.rosol}
          title="Chicken Soup (Rosół)"
          string="Most versitile concoction in the kitchen, a perfect base for soups and sauces and pretty great itself."
          src="/images/rosol1.jpg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.chlodnik}
          title="Chłodnik"
          string="Polish version of gazpacho. Perfect for hot summer day. Delicious and accidentally healthy."
          src="/images/chlodnik1.jpg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.cornChowder}
          title="Corn Chowder"
          string="Easy to make, delicious and utilising things that are always in my pantry."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.elote}
          title="Elote ake Cream Corn Soup"
          string="My own recipe, creamy spicy perfect for winter, mexican dinner."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.crayfish}
          title="Crayfish Soup"
          string="Royal treat. Take some time but it is worth it."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.eel}
          title="Eel (or Trout) Soup"
          string="Weird but fancy and fabulous."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.french}
          title="French Onion Soup"
          string="Classic French Onion Soup, great for when it's cold. Prepare your onion googles."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.fishChowder}
          title="Fish Chowder"
          string="gobOne of a kind fish chowder with Thai and Norwegian influences."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.krupnik}
          title="Vegetable & Muschroom Soup"
          string="Thick regional soup with wild muschroom and barley."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.lentil}
          title="Lentil Soup"
          string="Recipe is growing with me, being modyfied many times. This soup is rare combo of delicious, healthy and easy to prepare."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.potage}
          title="Leek & Potatoe Cream Soup"
          string="My version of classic. Served hot and steamy. Along with some fresh baguette can serve as a whole meal."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.tomato}
          title="Tomatoe (and Roast) Pepper Soup"
          string="Simple, delicious and healthy (unless you don't want it to be ;) )."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.vegetable}
          title="Vegetable Soup"
          string="Traditional, tasty and low in calories is a staple of almost every cuisine in the world - one way or the other."
          src="/images/flower.jpeg"
        />
      </div>
      <div class="cardClass">
        <Card
          href={soups.winterveggies}
          title="Winter Vegetables Cream"
          string="Hearthy, healthy and very easy to make."
          src="/images/flower.jpeg"
        />
      </div>


    </div>
  </div>
);

export default Soups;
