import "./recipe.scss";
import React from "react";

const Recipe = ({ title, ingredients, instructions, src, string }) => (
  <div className="recipeParent">
    <h3 className="recipeTitle">{title}</h3>
    <img class="recipePhoto" alt="foto" src={src} />
    <p className="description">{string}</p>
    <h1 className="INname">INGREDIENTS</h1>
    <ul className="recipeList">
      {ingredients.map((ingredient) => {
        return (
          <li className="ulStyle" key={ingredient}>
            {ingredient}
          </li>
        );
      })}
    </ul>
    <h1 className="INname">INSTRUCTIONS</h1>
    <ol className="recipeList">
      {instructions.map((instruction) => {
        return (
          <li className="ulStyle" key={instruction}>
            {instruction}
          </li>
        );
      })}
    </ol>
  </div>
);

export default Recipe;
