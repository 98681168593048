import Recipe from "../../components/Recipe/Recipe"

const CornChowder = () => (
  <Recipe
    title="CORN CHOWDER"
    src="/images/flower.jpeg"
    string="Easy to make, delicious and utilising things that are always in my pantry. Below is a basic, vegetarian version that is great on it's own but fried bacon bits or shredded chicken is always welcome. Also I used canned corn because it is available in my corner of the world, unlike fresh. "
    ingredients={[
      "2 400gr tin of sweet corn",
      "1 red bell pepper",
      "1 large onion (I use red for color but any will do)",
      "1 stalk of celery",
      "1 carrot",
      "350 ml of full fat milk, or you can swap some of it for cream if you are feeling indulgent",
      "1/2 liter of chicken stock (or in a pinch water and 11/2 bullion cube)",
      "2 tbsp sour cream",
      "SPICES: 1 bay leaf, salt, pepper, smoked paprika, 1/2 tsp chilli adobo minced (or however much you like) ", 
    ]}
    instructions={[
      "Drain one can of corn amix it with milk. Mix it until perfectly smooth. I use hand blender for that.",
      "In hot Dutch oven or pot fry the onion until translucent and then add remaining vegetables. Fry untill soft. Then add garlic and fry few more moments.",
      "Add chicken stock, and when it will start boiling add cubed potatoes and spices.",
      "When potato will be almost ready, add corn-milk mix. Remove bay leaves.",
      "Serve with splash of sour cream on the top and garnish with bacon or/and shredded chicken (if you want).",

    ]}
  />
);

export default CornChowder;
