import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HPcontent from "./components/HPcontent/HPcontent";
import Starters from "./components/Starters/Starters";
import Soups from "./components/Soups/Soups";
import Salads from "./components/Salads/Salads";
import Mains from "./components/Mains/Mains";
import Desserts from "./components/Desserts/Desserts";
import TipsTricks from "./components/TipsTricks/TipsTricks";

import { starters } from "./routes";
import AmericanPancakes from "./AllRecipes/StartersRecipes/AmericanPancakes";
import AsparagusMornay from "./AllRecipes/StartersRecipes/AsparagusMornay";
import Krokiety from "./AllRecipes/StartersRecipes/Krokiety";
import GrandmasPate from "./AllRecipes/StartersRecipes/Grandmas-pate";
import Guacamole from "./AllRecipes/StartersRecipes/Guacamole";
import Paszteciki from "./AllRecipes/StartersRecipes/Patties";
import PotatoeGratin from "./AllRecipes/StartersRecipes/PotatoeGratin";
import TomatoSalsa from "./AllRecipes/StartersRecipes/TomatoSalsa";
import WitlofMornay from "./AllRecipes/StartersRecipes/WitlofMornay";

import { soups } from "./routes";
import Borsht from "./AllRecipes/SoupsRecipes/Borsht";
import CheddarAndBroccoli from "./AllRecipes/SoupsRecipes/CheddarAndBroccoli";
import ChickenSoup from "./AllRecipes/SoupsRecipes/ChickenSoup";
import Chlodnik from "./AllRecipes/SoupsRecipes/Chlodnik";
import CornChowder from "./AllRecipes/SoupsRecipes/CornChowder";
import CornSoup from "./AllRecipes/SoupsRecipes/CornSoup";
import FishChowder from "./AllRecipes/SoupsRecipes/GunnarsFishSoup";
import CrayfishSoup from "./AllRecipes/SoupsRecipes/CrayfishSoup"
import Krupnik from "./AllRecipes/SoupsRecipes/Krupnik";
import LentilSoup from "./AllRecipes/SoupsRecipes/LentilSoup";
import Potage from "./AllRecipes/SoupsRecipes/PotatoeAndLeek";
import TomatoSoup from "./AllRecipes/SoupsRecipes/TomatoAndRoastPepper"
import VegetableSoup from "./AllRecipes/SoupsRecipes/Vegetable";
import WinterVeggies from "./AllRecipes/SoupsRecipes/WinterVeggies";


import { salads } from "./routes";
import Cesar from "./AllRecipes/SaladsRecipes/Cesar";
import Goat from "./AllRecipes/SaladsRecipes/GoatCheeseSalad";
import GreekSalad from "./AllRecipes/SaladsRecipes/GreekSalad";
import Panzanella from "./AllRecipes/SaladsRecipes/Panzanella";

import { mains } from "./routes";
import Zrazy from "./AllRecipes/MainsRecipes/BeefRoulades";
import Bolognese from "./AllRecipes/MainsRecipes/Bolognese";
import Burrito from "./AllRecipes/MainsRecipes/Burrito";
import Golabki from "./AllRecipes/MainsRecipes/CabbageRolls";
import Carbonara from "./AllRecipes/MainsRecipes/Carbonara";
import ChickenWine from "./AllRecipes/MainsRecipes/ChickenWine";
import ChickenPort from "./AllRecipes/MainsRecipes/ChickenPort";
import ChiliBeef from "./AllRecipes/MainsRecipes/ChilliBeef";
import Uszka from "./AllRecipes/MainsRecipes/Uszka";
import CoqAuVin from "./AllRecipes/MainsRecipes/CoqAuVin";
import CottagePie from "./AllRecipes/MainsRecipes/CottagePie";
import DuckConfit from "./AllRecipes/MainsRecipes/DuckConfit";
import Enchiladas from "./AllRecipes/MainsRecipes/Enchiladas";
import GoatPasta from "./AllRecipes/MainsRecipes/GoatCheesePasta";
import Falafel from "./AllRecipes/MainsRecipes/Falafel";
import Kotbullar from "./AllRecipes/MainsRecipes/Kotbullar";
import LambShanks from "./AllRecipes/MainsRecipes/LambShanks";
import Moussaka from "./AllRecipes/MainsRecipes/Moussaka";
import Quiche from "./AllRecipes/MainsRecipes/Quiche";
import Salmon from "./AllRecipes/MainsRecipes/SalmonCurry";
import ShepherdPie from "./AllRecipes/MainsRecipes/ShephardPie";
import ShreddedBeef from "./AllRecipes/MainsRecipes/ShreddedBeef";
import SmashBurgers from "./AllRecipes/MainsRecipes/SmashBurgers";
import SpinachPasta from "./AllRecipes/MainsRecipes/SpinachPasta";
import MuschroomPasta from "./AllRecipes/MainsRecipes/MushroomPasta";
import Tortellini from "./AllRecipes/MainsRecipes/Tortellini";

import { desserts } from "./routes";
import ApplePie from "./AllRecipes/DessertsRecipes/ApplePie";
import Babka from "./AllRecipes/DessertsRecipes/ApplePie";
import CarrotCake from "./AllRecipes/DessertsRecipes/CarrotCake";
import Cheesecake from "./AllRecipes/DessertsRecipes/Cheesecake";
import Cookies from "./AllRecipes/DessertsRecipes/ChocolateCookies";
import Migdalowiec from "./AllRecipes/DessertsRecipes/Migdalowiec";
import Madeleines from "./AllRecipes/DessertsRecipes/Madeleines";
import Muffins from "./AllRecipes/DessertsRecipes/Muffins";
import Pishinger from "./AllRecipes/DessertsRecipes/Pishinger";
import PoppyLoaf from "./AllRecipes/DessertsRecipes/PoppyLoaf";
import Tiramisu from "./AllRecipes/DessertsRecipes/Tiramisu";
import YamPie from "./AllRecipes/DessertsRecipes/YamPie";
import EelSoup from "./AllRecipes/SoupsRecipes/EelSoup";
import FrenchOnion from "./AllRecipes/SoupsRecipes/FrenchOnionSoup";
import YorkshirePudding from "./AllRecipes/StartersRecipes/YorkshirePudding";



function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HPcontent />}></Route>
          <Route path={starters.root} element={<Starters />}></Route>
          <Route path="/Soups" element={<Soups />}></Route>
          <Route path="/Salads" element={<Salads />}></Route>
          <Route path="/Mains" element={<Mains />}></Route>
          <Route path="/Desserts" element={<Desserts />}></Route>
          <Route path="/TipsTricks" element={<TipsTricks />}></Route>

          <Route path={starters.panacakes} element={<AmericanPancakes />}></Route>
          <Route path={starters.mornay} element={< AsparagusMornay />}></Route>
          <Route path={starters.krokiety} element={< Krokiety />}></Route>
          <Route path={starters.pate} element={< GrandmasPate />}></Route>
          <Route path={starters.guacamole} element={< Guacamole />}></Route>
          <Route path={starters.patties} element={< Paszteciki />}></Route>
          <Route path={starters.gratin} element={< PotatoeGratin />}></Route>
          <Route path={starters.salsa} element={< TomatoSalsa />}></Route>
          <Route path={starters.witlof} element={< WitlofMornay />}></Route>
          <Route path={starters.yorkshire} element={< YorkshirePudding />}></Route>


          <Route path={soups.borsht} element={< Borsht />}></Route>
          <Route path={soups.broccoli} element={< CheddarAndBroccoli />}></Route>
          <Route path={soups.rosol} element={< ChickenSoup />}></Route>
          <Route path={soups.chlodnik} element={< Chlodnik />}></Route>
          <Route path={soups.cornChowder} element={< CornChowder />}></Route>
          <Route path={soups.elote} element={< CornSoup />}></Route>
          <Route path={soups.crayfish} element={< CrayfishSoup />}></Route>
          <Route path={soups.eel} element={< EelSoup />}></Route>
          <Route path={soups.french} element={< FrenchOnion />}></Route>
          <Route path={soups.fishChowder} element={< FishChowder />}></Route>
          <Route path={soups.krupnik} element={< Krupnik />}></Route>
          <Route path={soups.lentil} element={< LentilSoup />}></Route>
          <Route path={soups.potage} element={< Potage />}></Route>
          <Route path={soups.tomato} element={< TomatoSoup />}></Route>
          <Route path={soups.vegetable} element={< VegetableSoup />}></Route>
          <Route path={soups.winterveggies} element={< WinterVeggies />}></Route>


          <Route path={salads.cesar} element={< Cesar />}></Route>
          <Route path={salads.goat} element={< Goat />}></Route>
          <Route path={salads.greek} element={< GreekSalad />}></Route>
          <Route path={salads.panzanella} element={< Panzanella />}></Route>

          <Route path={mains.zrazy} element={< Zrazy />}></Route>
          <Route path={mains.bolognese} element={< Bolognese />}></Route>
          <Route path={mains.burrito} element={< Burrito />}></Route>
          <Route path={mains.golabki} element={< Golabki />}></Route>
          <Route path={mains.carbonara} element={< Carbonara />}></Route>
          <Route path={mains.portChicken} element={< ChickenPort />}></Route>
          <Route path={mains.wineChicken} element={< ChickenWine />}></Route>
          <Route path={mains.chilli} element={< ChiliBeef />}></Route>
          <Route path={mains.coqAuVin} element={< CoqAuVin />}></Route>
          <Route path={mains.cottagePie} element={< CottagePie />}></Route>
          <Route path={mains.duck} element={< DuckConfit />}></Route>
          <Route path={mains.enchiladas} element={< Enchiladas />}></Route>
          <Route path={mains.falafel} element={< Falafel />}></Route>
          <Route path={mains.goatPasta} element={< GoatPasta />}></Route>
          <Route path={mains.köttbullar} element={< Kotbullar />}></Route>
          <Route path={mains.lamb} element={< LambShanks />}></Route>
          <Route path={mains.moussaka} element={< Moussaka />}></Route>
          <Route path={mains.muschroomPasta} element={< MuschroomPasta />}></Route>
          <Route path={mains.quiche} element={< Quiche />}></Route>
          <Route path={mains.salmon} element={< Salmon />}></Route>
          <Route path={mains.shepherdPie} element={< ShepherdPie />}></Route>
          <Route path={mains.shreddedBeef} element={< ShreddedBeef />}></Route>
          <Route path={mains.smashBurgers} element={< SmashBurgers />}></Route>
          <Route path={mains.spinachPasta} element={< SpinachPasta />}></Route>
          <Route path={mains.tortellini} element={< Tortellini />}></Route>
          <Route path={mains.uszka} element={< Uszka />}></Route>

          <Route path={desserts.applePie} element={< ApplePie />}></Route>
          <Route path={desserts.babka} element={< Babka />}></Route>
          <Route path={desserts.carrot} element={< CarrotCake />}></Route>
          <Route path={desserts.cheesecake} element={< Cheesecake />}></Route>
          <Route path={desserts.cookies} element={< Cookies />}></Route>
          <Route path={desserts.migdalowiec} element={< Migdalowiec />}></Route>
          <Route path={desserts.madeleines} element={< Madeleines />}></Route>
          <Route path={desserts.muffins} element={< Muffins />}></Route>
          <Route path={desserts.pishinger} element={< Pishinger />}></Route>
          <Route path={desserts.poppyLoaf} element={< PoppyLoaf />}></Route>
          <Route path={desserts.tiramisu} element={< Tiramisu />}></Route>
          <Route path={desserts.yamPie} element={< YamPie />}></Route>





        </Routes>
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
