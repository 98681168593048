import Recipe from "../../components/Recipe/Recipe"

const CheddarAndBroccoli = () => (
  <Recipe
    title="CHEDDAR & BROCOLI SOUP"
    string="Family fauvorite (including small children). This is very fulfilling and hearthy soup that often serve as a dinner itself, especially served with garlick baguette or salad on the side. If you are affraid it's going to split you can replace some of the cheese with processed American cheese."
    src="/images/flower.jpg"
    ingredients={[
      "1 head of broccoli (or 500gr frozen florets, but fresh is better)",
      "1 onion",
      "1 selery stalk",
      "1 carrot julienned (optional)",
      "500 ml (2 cups) full fat milk (4%)",
      "500 ml (2 cups) chicken stock",
      "1 large tbsp APF",
      "1 large tbsp butter",
      "250 gr (more or less) grated cheddar",
      "SPICES: salt (unlikely),pinch of white pepper and 1/2 tsp nutmeg",

    ]}
    instructions={[
      "Melt butter in a pot and add onion and celery. Fry gently untill softend.",
      "Sprinkle with flour and gently fry for 30 secunds.",
      "Add chicken stock, broccoli and carrot. Boil gently under the cover untill broccoli is not soft but tender.",
      "Add milk. Bring it gently to the boil. Then switch off and start add cheese in portions. If you use processed cheese start with it.",
      "Once all the cheese has melted check for salt. I doubt you will need any. So add pepper and nutmeg. It's ready to eat.",

    ]}
  />
);

export default CheddarAndBroccoli;
