import Recipe from "../../components/Recipe/Recipe"

const Borsht = () => (
  <Recipe
    title="Borsht (Barszcz)"
    src="/images/barszcz&uszka1.jpg"
    string="Polish christmas staple. Worth extra effort with sour starter."
    ingredients={[
      "1kg beetroots",
      "1 1/2 liter of vegetable or chicken stock",
      "1 cup of flour (120 gr)",
      "2 parley roots or parsnips",
      "1 carrot",
      "4 cloves of garlic peeled and halved",
      "1 leek (green parts removed)",
      "1 cup sour starter made of beets*",
      "SPICES: 1 bay leaf, 10 grains of pepper, 5 grains of all spice, salt (as much as you like), 1 tsp sugar, 1 pinch of chilli powder.",
      "OPTIONALY: 1/2 cup water that muschrooms were steeped in",
    ]}
    instructions={[
      "You can buy it, but if you want to make it from scratch, you need to make sour starter 10 days ahead. Good news - it's not difficult: sterylise medium jar. Add two peeled and sliced beetroot inside. Add non peeled garlic, two bay leafs and loads of pepper and 1/2 tsp of salt. Top it all with tepid boiled water. Leave for 10 days.",
      "If you are going to make uszka you will youse lot of dried porcini. You going to soak tem in the water first. That water is fantastic for sauces (like Chicken in Port Wine) but you can add it to the soup itself.",
      "Wash and peel all the vegetables. Chop them in sensible pieces.",
      "Top it with stock (or water in a pinch) and add spices. Gently bring to the boil and keep it boiling for no longer then 35 min.",
      "Turn it off and check for salt and acidity.",
      "Serve with or next to uszka or mushroom croquettes or meat patties. Or anything really!",
    ]}
  />
);

export default Borsht;
